import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {directive as onClickaway} from 'vue-clickaway';
import changeHtmlLang from "@/utils/change-html-lang";
import {debounce} from 'lodash'
import headerFavorites from '@/components/header/components/favorites/index.vue'
import headerCart from '@/components/header/components/cart/index.vue'
import search from "./components/search/index.vue";
import {fixBody} from "../../helpers/variables";

export default {
  name: "header",
  watch: {
    '$route'(newVal){
      if(newVal){
        this.burgerToggle = false
        this.searchState = false
        if(this.$route.name === 'profile'){this.showFavorites=false}
        if(this.$route.name === 'order'){this.showCart=false}
        if(this.$route.name === 'sign-in'){this.showFavorites=false}
      }
    },
    searchState: {
      immediate: true,
      handler(newVal){
         fixBody(newVal)
      }
    }
  },
  created() {
    this.fetchCategories()
  },
  components: {
    headerFavorites,
    headerCart,
    search,
  },
  data(){
    return{
      scroller: false,
      burgerToggle: false,
      showLangList: false,
      showFavorites: false,
      showCart: false,
      searchState: false,
      searchValue: '',
    }
  },
  directives: {
    onClickaway: onClickaway,
  },
  methods: {
    ...mapMutations({
      // changeLoginPopup:'popups/CHANGE_LOGIN_POPUP'
    }),
    ...mapActions({
      fetchCategories: `category/GET_LIST_CATEGORY`,
      fetchSearch: `home/GET_SEARCH_LIST`
    }),
    changeSearch: debounce(function (event) {
      this.fetchSearch(this.searchValue).then(()=>{
        console.log(this.searchList);
      })
    }, 300),

    changeLanguage(language){
      const selectedLanguage = this.languages.find(item => item.key === language.key);

      this.$setLanguage(selectedLanguage.code);
      localStorage.setItem('localization', selectedLanguage.key);
      changeHtmlLang(selectedLanguage.key);

      this.showLangList = false;

      setTimeout(() => {
        window.location.reload();
      }, 800)
    },
    closeLangList(){
      this.showLangList = false;
    },
    closeCart(){
      this.showCart = false;
    },
    closeFavorites(){
      this.showFavorites = false;
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
    ...mapGetters({
      favoriteList: 'basket/favorites',
      categoriesList: 'category/categoriesList',
      isAuthenticated: 'auth/isAuthenticated',
      products: 'basket/basket',
      searchList: 'home/searchList',
      searchListLoading: 'home/searchListLoading',
      isRetailer: 'profile/isRetailer'
    }),
    cartCount(){
      if(this.products){
        let count = 0
        this.products.forEach((e)=>{
          count = count + e.selectCount
        })
        return count
      }
      else {return 0}
    },
  },
  mounted() {
    let that = this
    window.addEventListener('scroll', function(e) {
      if(window.pageYOffset > 200){
        that.scroller = true
      }
      else {
        that.scroller = false
      }
    });
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  destroyed() {

  }
}