import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next();
    return
  }
  next('/')
};
const notAuthenticated = (to, from, next) => {
  if (!store.state.auth.isAuthenticated) {
    next();
    return
  }
  next('/')
};
const routes = [
  {
    path: '/:lang?',
    name: 'home',
    component: () => import('../modules/home/index.vue')
  },
  {
    path: '/:lang?/profile',
    redirect: '/:lang?/profile/user-info',
    name: 'profile',
    beforeEnter: isAuthenticated,
    component: () => import('../modules/profile/index.vue'),
    children: [
      {
        path: 'user-info',
        name: 'profile-user-info',
        component: () => import('../modules/profile/info/index.vue'),
      },
      {
        path: 'favourites',
        name: 'profile-favourites',
        component: () => import('../modules/profile/favorites/index.vue'),
      },
      {
        path: 'order-list',
        name: 'profile-order-list',
        component: () => import('../modules/profile/orders/index.vue'),
      },
      {
        path: 'retail-order-list',
        name: 'retail-order-list',
        component: () => import('../modules/profile/orders-retail/index.vue'),
      },
    ]
  },
  {
    path: '/:lang?/order',
    name: 'order',
    component: () => import('../modules/order/index.vue')
  },
  {
    path: '/:lang?/retailers',
    name: 'retailers',
    component: () => import('../modules/retailers/index.vue')
  },
  {
    path: '/:lang?/wishlist',
    name: 'wishlist',
    component: () => import('../modules/favorites/index.vue')
  },
  {
    path: '/:lang?/success-payment',
    name: 'success-payment',
    component: () => import('../modules/success-payment/index.vue')
  },
  {
    path: '/:lang?/sign-in',
    beforeEnter: notAuthenticated,
    name: 'sign-in',
    component: () => import('../modules/auth/sign-in/index.vue')
  },
  // {
  //     path: '/:lang?/register',
  //     beforeEnter: notAuthenticated,
  //     name: 'register',
  //     component: () => import('../modules/auth/register/index.vue')
  // },
  {
    path: '/:lang?/contact',
    name: 'contact',
    component: () => import('@/modules/contact/index.vue'),
    children: [
      {
        path: 'bride',
        name: 'bride',
        component: () => import('@/modules/contact/modules/bride /index.vue'),
      },
      {
        path: 'salon',
        name: 'salon',
        component: () => import('@/modules/contact/modules/salon/index.vue'),
      },
    ]
  },
  {
    path: '/:lang?/success-registration',
    beforeEnter: notAuthenticated,
    name: 'success-registration',
    component: () => import('../modules/auth/success-registration/index.vue')
  },
  {
    path: '/:lang?/success-restore',
    beforeEnter: notAuthenticated,
    name: 'success-restore',
    component: () => import('../modules/auth/success-restore/index.vue')
  },
  {
    path: '/:lang?/reset-password',
    beforeEnter: notAuthenticated,
    name: 'reset',
    component: () => import('../modules/auth/reset/index.vue')
  },
  {
    path: '/:lang?/restore',
    beforeEnter: notAuthenticated,
    name: 'restore',
    component: () => import('../modules/auth/change/index.vue')
  },
  {
    path: '/:lang?/blog',
    name: 'blog',
    component: () => import('../modules/blog/index.vue')
  },
  {
    path: '/:lang?/video',
    name: 'video',
    component: () => import('../modules/video/index.vue')
  },
  {
    path: '/:lang?/meeting-place',
    name: 'meeting-place',
    component: () => import('../modules/meeting/index.vue'),
    redirect: '/:lang?/meeting-place/exhibitions',
    children: [
      {
        path: 'exhibitions',
        name: 'exhibitions',
        component: () => import('../modules/meeting/exhibitions/index.vue'),
      },
      {
        path: 'trunk-show',
        name: 'trunk-list',
        component: () => import('../modules/meeting/trunk-list/index.vue'),
      },
    ]
  },
  {
    path: '/:lang?/meeting-place/trunk-show/:slug',
    name: 'trunk',
    component: () => import('../modules/meeting/trunk/index.vue')
  },
  {
    path: '/:lang?/meeting/:slug',
    name: 'meet',
    component: () => import('../modules/meet/index.vue')
  },
  {
    path: '/:lang?/about',
    name: 'about',
    component: () => import('../modules/about/index.vue')
  },
  {
    path: '/:lang?/blog/article/:slug',
    name: 'article',
    component: () => import('../modules/article/index.vue')
  },
  {
    path: '/:lang?/evening/:slug',
    name: 'evening',
    component: () => import('../modules/catalog/index.vue')
  },
  {
    path: '/:lang?/accessories/:slug',
    name: 'accessories',
    component: () => import('../modules/catalog/index.vue')
  },
  {
    path: '/:lang?/wedding/:slug',
    name: 'wedding',
    component: () => import('../modules/catalog/index.vue')
  },
  {
    path: '/:lang?/shop-online',
    name: 'shop',
    component: () => import('../modules/shop/index.vue')
  },
  {
    path: '/:lang?/evening/card/:slug',
    name: 'evening-card',
    component: () => import('../modules/product/index.vue')
  },
  {
    path: '/:lang?/accessories/card/:slug',
    name: 'accessories-card',
    component: () => import('../modules/product/index.vue')
  },
  {
    path: '/:lang?/wedding/card/:slug',
    name: 'wedding-card',
    component: () => import('../modules/product/index.vue')
  },
  {
    path: '/:lang?/sizes',
    name: 'sizes',
    component: () => import('../modules/sizes/index.vue')
  },
  {
    path: '/:lang?/collections',
    name: 'collections',
    component: () => import('../modules/collections/index.vue')
  },
  {
    path: '/:lang?/collections/:slug',
    name: 'collection',
    component: () => import('../modules/collection/index.vue')
  },
  {
    path: '/:lang?/checkout',
    name: 'checkout',
    component: () => import('../modules/checkout/index.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        if (to.name === 'wedding' || to.name === 'evening' || to.name === 'accessories') {
          store.state.catalog.top = savedPosition.y
          return savedPosition
        }
      } else {
        el.scrollTop = 0;

        return {x: 0, y: 0}
      }
    }
  }
});

export default router
