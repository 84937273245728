import {$http} from '@/utils/https';

import {CHANGE_LOADING_SUBMIT_RETAILER, CHANGE_LOADING_DELETE_DRESS, CHANGE_LOADING_RETAIL_EMAIL, CHANGE_LOADING_DOWNLOAD_CART, SET_RETAILER_CART, CHANGE_LOADING_RETAILER_CART, CHANGE_LOADING_ADD_DRESS_TO_CART, SET_COLLECTION_LIST, CHANGE_LOADING_COLLECTION_LIST, SET_COLLECTION_DRESS_LIST, CHANGE_LOADING_COLLECTION_DRESS_LIST, SET_COLLECTION_DATA, CHANGE_LOADING_GET_COLLECTION_DATA} from '../mutation-types';

import {SUBMIT_RETAILER, DELETE_DRESS, RETAIL_EMAIL, DOWNLOAD_CART, GET_RETAILER_CART, ADD_DRESS_TO_CART, GET_COLLECTION_LIST, GET_COLLECTION_DRESS_LIST, GET_COLLECTION_DATA} from '../action-types';

const state = {
  collectionList: null,
  collectionListLoading: false,
  collectionData: null,
  collectionDataLoading: false,
  collectionDressList: null,
  collectionDressListLoading: false,
  addDressToCartLoading: false,
  retailerCart: null,
  retailerCartLoading: false,
  retailerCartDownloadLoading: false,
  retailerEmailLoading: false,
  retailerDeleteDressLoading: false,
  retailerSubmitLoading: false,
};

const getters = {
  collectionList: state => state.collectionList,
  collectionListLoading: state => state.collectionListLoading,
  collectionData: state => state.collectionData,
  collectionDataLoading: state => state.collectionDataLoading,
  collectionDressList: state => state.collectionDressList,
  collectionDressListLoading: state => state.collectionDressListLoading,
  addDressToCartLoading: state => state.addDressToCartLoading,
  retailerCart: state => state.retailerCart,
  retailerCartLoading: state => state.retailerCartLoading,
  retailerCartDownloadLoading: state => state.retailerCartDownloadLoading,
  retailerEmailLoading: state => state.retailerEmailLoading,
  retailerDeleteDressLoading: state => state.retailerDeleteDressLoading,
  retailerSubmitLoading: state => state.retailerSubmitLoading,
};

const actions = {
  [GET_COLLECTION_LIST]: async ({commit}) => {
    commit(CHANGE_LOADING_COLLECTION_LIST, true);
    try {
      const result = await $http.get(`v1/retail_dress/collections`);
      commit(SET_COLLECTION_LIST, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_COLLECTION_LIST, false)
    }
  },
  [GET_COLLECTION_DATA]: async ({commit}, slug) => {
    commit(CHANGE_LOADING_GET_COLLECTION_DATA, true);
    try {
      const result = await $http.get(`v1/retail_dress/collections/${slug}`);
      commit(SET_COLLECTION_DATA, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_GET_COLLECTION_DATA, false)
    }
  },
  [GET_COLLECTION_DRESS_LIST]: async ({commit}, slug) => {
    commit(CHANGE_LOADING_COLLECTION_DRESS_LIST, true);
    try {
      const result = await $http.get(`v1/retail_dress?slug=${slug}`);
      commit(SET_COLLECTION_DRESS_LIST, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_COLLECTION_DRESS_LIST, false)
    }
  },
  [ADD_DRESS_TO_CART]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_ADD_DRESS_TO_CART, true);
    try {
      return await $http.post(`v1/cart/add`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_ADD_DRESS_TO_CART, false)
    }
  },
  [RETAIL_EMAIL]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_RETAIL_EMAIL, true);
    try {
      return await $http.post(`v1/cart/send-mails`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_RETAIL_EMAIL, false)
    }
  },
  [DELETE_DRESS]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_DELETE_DRESS, true);
    try {
      return await $http.post(`v1/cart/drop`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_DELETE_DRESS, false)
    }
  },
  [GET_RETAILER_CART]: async ({commit}) => {
    commit(CHANGE_LOADING_RETAILER_CART, true);
    try {
      const result = await $http.get(`v1/cart`);
      commit(SET_RETAILER_CART, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_RETAILER_CART, false)
    }
  },
  [DOWNLOAD_CART]: async ({commit}) => {
    commit(CHANGE_LOADING_DOWNLOAD_CART, true);
    try {
      return  await $http.post(`v1/cart/download`);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_DOWNLOAD_CART, false)
    }
  },
  [SUBMIT_RETAILER]: async ({commit}) => {
    commit(CHANGE_LOADING_SUBMIT_RETAILER, true);
    try {
      return  await $http.post(`v1/order/retail`);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SUBMIT_RETAILER, false)
    }
  },
};

const mutations = {
  [SET_COLLECTION_LIST](state, data) {
    state.collectionList = data
  },
  [CHANGE_LOADING_COLLECTION_LIST](state, status) {
    state.collectionListLoading = status;
  },
  [SET_COLLECTION_DATA](state, data) {
    state.collectionData = data
  },
  [CHANGE_LOADING_ADD_DRESS_TO_CART](state, status) {
    state.addDressToCartLoading = status;
  },
  [CHANGE_LOADING_GET_COLLECTION_DATA](state, status) {
    state.collectionDataLoading = status;
  },
  [SET_COLLECTION_DRESS_LIST](state, data) {
    state.collectionDressList = data
  },
  [CHANGE_LOADING_COLLECTION_DRESS_LIST](state, status) {
    state.collectionDressListLoading = status;
  },
  [SET_RETAILER_CART](state, data) {
    state.retailerCart = data
  },
  [CHANGE_LOADING_RETAILER_CART](state, status) {
    state.retailerCartLoading = status;
  },
  [CHANGE_LOADING_DOWNLOAD_CART](state, status) {
    state.retailerCartDownloadLoading = status;
  },
  [CHANGE_LOADING_RETAIL_EMAIL](state, status) {
    state.retailerEmailLoading = status;
  },
  [CHANGE_LOADING_DELETE_DRESS](state, status) {
    state.retailerDeleteDressLoading = status;
  },
  [CHANGE_LOADING_SUBMIT_RETAILER](state, status) {
    state.retailerSubmitLoading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
