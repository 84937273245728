//auth
export const SET_TOKEN = 'SET_TOKEN';
export const SET_MAGAZINES = 'SET_MAGAZINES';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const CHANGE_POPUP_RESTORE_PASSWORD = 'CHANGE_POPUP_RESTORE_PASSWORD';
export const CHANGE_POPUP_NEW_PASSWORD = 'CHANGE_POPUP_NEW_PASSWORD';
export const SET_LOADING_REGISTRATION = 'SET_LOADING_REGISTRATION';
export const CHANGE_POPUP_LDAP = 'CHANGE_POPUP_LDAP';
export const SING_IN_WITH_LDAP_LOADING = 'SING_IN_WITH_LDAP_LOADING';
export const CHECK_EMAIL_LOADING = 'CHECK_EMAIL_LOADING';
export const CHANGE_LOADING_SING_IN = 'CHANGE_LOADING_SING_IN';

export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const FIX_BODY = 'FIX_BODY';
//settings
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_CATALOG_MENU_LOADING = 'SET_CATALOG_MENU_LOADING';
export const SET_CATALOG_MENU = 'SET_CATALOG_MENU';
export const CHANGE_SELECTED_CATEGOTY = 'CHANGE_SELECTED_CATEGOTY';
export const SET_LIST_CATEGORY_FILTERS = 'SET_LIST_CATEGORY_FILTERS';
export const SET_LIST_CATEGORY_FILTERS_LOADING = 'SET_LIST_CATEGORY_FILTERS_LOADING';
export const SET_VARIABLES = 'SET_VARIABLES';
//system
export const SET_CUSTOM_BREADCRUMBS_LIST = 'SET_CUSTOM_BREADCRUMBS_LIST';
export const RESET_CUSTOM_BREADCRUMBS_LIST = 'RESET_CUSTOM_BREADCRUMBS_LIST';
export const CHANGE_OPEN_MENU = 'CHANGE_OPEN_MENU';
export const CHANGE_OPEN_SECOND_MENU = 'CHANGE_OPEN_SECOND_MENU';
export const CHANGE_OPEN_FIRST_MENU = 'CHANGE_OPEN_FIRST_MENU';
export const CHANGE_OPEN_FILTER = 'CHANGE_OPEN_FILTER';
export const SET_PROPS_SECOND_MENU = 'SET_PROPS_SECOND_MENU';
//home
//basket
export const ADD_ITEM_TO_BASKET = 'ADD_ITEM_TO_BASKET';
export const ADD_ITEM_TO_FAVORITES = 'ADD_ITEM_TO_FAVORITES';
export const REMOVE_ITEM_FROM_BASKET = 'REMOVE_ITEM_FROM_BASKET';
export const REMOVE_ITEM_FROM_FAVORITES = 'REMOVE_ITEM_FROM_FAVORITES';
export const RESET_BASKET = 'RESET_BASKET';
export const CHECK_PROMO_CODE_LOADING = 'CHECK_PROMO_CODE_LOADING';
export const SET_BASKET_EDIT_COUNT = 'SET_BASKET_EDIT_COUNT';
export const REPLACE_ITEM_IS_BASKET = 'REPLACE_ITEM_IS_BASKET';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const RESET_DISCOUNT = 'RESET_DISCOUNT';
export const SET_PROMO_CODE_IN_BASKET = 'SET_PROMO_CODE_IN_BASKET';
export const SET_DATA_REMOVE_FROM_BASKET = 'SET_DATA_REMOVE_FROM_BASKET';
export const SHOW_POPUP_REMOVE_FROM_BASKET = 'SHOW_POPUP_REMOVE_FROM_BASKET';
export const INCREMENT_BASKET_LIST_COUNT = 'INCREMENT_BASKET_LIST_COUNT';
export const DECREMENT_BASKET_LIST_COUNT = 'DECREMENT_BASKET_LIST_COUNT';
export const SET_BASKET_LIST_COUNT = 'SET_BASKET_LIST_COUNT';
export const FRESH_BASKET_ITEMS = 'FRESH_BASKET_ITEMS';

export const SET_USER_DATA = 'SET_USER_DATA';
export const LOADING_USER_DATA = 'LOADING_USER_DATA';
export const CHANGE_LOADING_LIST = 'CHANGE_LOADING_LIST';
export const CHANGE_SKIP = 'CHANGE_SKIP';
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH';
export const SET_LIST = 'SET_LIST';
export const GET_FILTERS_LOADING = 'GET_FILTERS_LOADING';
export const SET_FILTERS = 'SET_FILTERS';
export const REMOVE_FILTER_ITEM_IN_SEARCH = 'REMOVE_FILTER_ITEM_IN_SEARCH';
export const UPDATE_CHECKED_LIST_FILTER = 'UPDATE_CHECKED_LIST_FILTER';
export const CHANGE_LIMIT = 'CHANGE_LIMIT';
export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const SET_PRODUCT = 'SET_PRODUCT';
export const CHANGE_PRODUCT_LOADING = 'CHANGE_PRODUCT_LOADING';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const GET_ITEM_LOADING = 'GET_ITEM_LOADING';
export const SET_ITEM = 'SET_ITEM';
export const GET_LIST_LOADING = 'GET_LIST_LOADING';
export const UPDATE_USER_DATA_LOADING = 'UPDATE_USER_DATA_LOADING';
export const SHOW_POPUP_UPDATE_PASSWORD = 'SHOW_POPUP_UPDATE_PASSWORD';
export const UPDATE_USER_PASSWORD_LOADING = 'UPDATE_USER_PASSWORD_LOADING';
export const CHANGE_LOADING = 'CHANGE_LOADING';
export const ADD_ITEM_TO_LIST = 'ADD_ITEM_TO_LIST';
export const REPLACE_ITEM_FROM_LIST = 'REPLACE_ITEM_FROM_LIST';
export const REMOVE_ITEM_FROM_LIST = 'REMOVE_ITEM_FROM_LIST';
export const CHANGE_EDITED_ITEM = 'CHANGE_EDITED_ITEM';
export const CHANGE_STATUS_DIALOG_EDIT = 'CHANGE_STATUS_DIALOG_EDIT';
export const CHANGE_REMOVED_ITEM = 'CHANGE_REMOVED_ITEM';
export const CHANGE_STATUS_DIALOG_REMOVE = 'CHANGE_STATUS_DIALOG_REMOVE';
export const CHANGE_DIALOG_QUICK_ORDER = 'CHANGE_DIALOG_QUICK_ORDER';
export const QUICK_ORDER_LOADING = 'QUICK_ORDER_LOADING';
export const CHANGE_ADDRESS = 'CHANGE_ADDRESS';
export const RESET_ADDRESS = 'RESET_ADDRESS';
export const NOVA_POSHTA_GET_TOWNS_LOADING = 'NOVA_POSHTA_GET_TOWNS_LOADING';
export const NOVA_POSHTA_SET_TOWNS = 'NOVA_POSHTA_SET_TOWNS';
export const NOVA_POSHTA_GET_DEPARTMENS_LOADING = 'NOVA_POSHTA_GET_DEPARTMENS_LOADING';
export const NOVA_POSHTA_SET_DEPARTMENS = 'NOVA_POSHTA_SET_DEPARTMENS';
export const CHANGE_SUCCESS_BUY = 'CHANGE_SUCCESS_BUY';
export const CHANGE_FILTER = 'CHANGE_FILTER';

export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const RESET_CHANGE_USER_INFO = 'RESET_CHANGE_USER_INFO';
export const CHANGE_DELIVERY_TYPE = 'CHANGE_DELIVERY_TYPE';
export const CHANGE_ADDRESS_LOADING = 'CHANGE_ADDRESS_LOADING';
export const SET_CONTACTS = 'SET_CONTACTS';
export const CHANGE_STATUS_FAVORITE = 'CHANGE_STATUS_FAVORITE';
export const CHANGE_CATEGORY_ID = 'CHANGE_CATEGORY_ID';


// favourites
export const ADD_ITEM_TO_FAVOURITES = 'ADD_ITEM_TO_FAVOURITES';
export const ADD_PRODUCT_TO_FAVORITE_LOADING = 'ADD_PRODUCT_TO_FAVORITE_LOADING';
export const REMOVE_ITEM_FROM_FAVOURITES = 'REMOVE_ITEM_FROM_FAVOURITES';
export const REMOVE_PRODUCT_IN_FAVORITE_LOADING = 'REMOVE_PRODUCT_IN_FAVORITE_LOADING';
export const SET_FAVORITES_LIST = 'SET_FAVORITES_LIST';
export const SET_FAVOURITES_LIST_LENGTH = 'SET_FAVOURITES_LIST_LENGTH';
export const SET_FAVOURITES_LENGTH = 'SET_FAVOURITES_LENGTH';
export const SET_FAVORITES_LIST_LOADING = 'SET_FAVORITES_LIST_LOADING';
export const FAVOURITES_CHANGE_PAGE = 'FAVOURITES_CHANGE_PAGE';
export const SET_SELECT_FAVOURITES_ITEM = 'SET_SELECT_FAVOURITES_ITEM';
//payment
export const SET_INVOICE = 'SET_INVOICE';
export const CHANGE_INVOICE_LOADING = 'CHANGE_INVOICE_LOADING';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const CHANGE_TRANSACTION_LOADING = 'CHANGE_TRANSACTION_LOADING';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const SET_ORDER = 'SET_ORDER';

//POPUPS
export const CHANGE_LOGIN_POPUP = 'CHANGE_LOGIN_POPUP';
export const CHANGE_REGISTRATION_POPUP = 'CHANGE_REGISTRATION_POPUP';
export const CHANGE_RESET_POPUP = 'CHANGE_RESET_POPUP';
export const CHANGE_RESTORE_POPUP = 'CHANGE_RESTORE_POPUP';


//catalog
export const CHANGE_PRODUCTS_LOADING = 'CHANGE_PRODUCTS_LOADING';
export const SET_PRODUCTS = 'SET_PRODUCTS';

//content
export const CHANGE_CONTENT_LOADING = 'CHANGE_CONTENT_LOADING';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_BANNER_CONTENT = 'SET_BANNER_CONTENT';
export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT';
export const SET_PAGE = 'SET_PAGE';
export const SET_BLOG = 'SET_BLOG';

//dress
export const SET_DRESS = 'SET_DRESS';

//retailers
export const CHANGE_LOADING_LOCATION = 'CHANGE_LOADING_LOCATION';
export const SET_LOCATION = 'SET_LOCATION';
export const CHANGE_LOADING_COUNTRIES = 'CHANGE_LOADING_COUNTRIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const CHANGE_LOADING_CITIES = 'CHANGE_LOADING_CITIES';
export const SET_CITIES = 'SET_CITIES';

//delivery
export const SET_DELIVERY = 'SET_DELIVERY';
export const CHANGE_DELIVERY_LOADING = 'CHANGE_DELIVERY_LOADING'
export const SET_COUNTRY = 'SET_COUNTRY';
export const CHANGE_COUNTRY_LOADING = 'CHANGE_COUNTRY_LOADING'

//profile orders
export const SET_ORDERS = 'SET_ORDERS';
export const LOADING_ORDERS = 'LOADING_ORDERS'

//contact
export const SET_CONTACT = 'SET_CONTACT';

//contact us
export const SET_CONTACTS_COUNTRY = 'SET_CONTACTS_COUNTRY'
export const GET_BRIDE_DATA = 'SET_BRIDE_DATA'
export const GET_SALON_DATA = 'SET_SALON_DATA'
export const BRIDE_LOADING = 'BRIDE_LOADING'
export const SALON_LOADING = 'SALON_LOADING'
export const SET_SOCIALS = 'SET_SOCIALS'
export const SET_TRUNK = 'SET_TRUNK'
export const SET_TRUNK_SHOW = 'SET_TRUNK_SHOW'


//skip take
export const CHANGE_PAGE_SKIP = 'CHANGE_PAGE_SKIP'
export const RESET_SKIP = 'RESET_SKIP'
export const CHANGE_COLLECTIONS_LOADING = 'CHANGE_COLLECTIONS_LOADING'
export const SET_COLLECTIONS = 'SET_COLLECTIONS'
export const SET_TOP_POSITION = 'SET_TOP_POSITION'
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST'
export const CHANGE_LOADING_SEARCH = 'CHANGE_LOADING_SEARCH'
export const SET_RETAIL = 'SET_RETAIL'

//collections
export const CHANGE_LOADING_COLLECTION_LIST = 'CHANGE_LOADING_COLLECTION_LIST'
export const SET_COLLECTION_LIST = 'SET_COLLECTION_LIST'
export const SET_COLLECTION_DRESS_LIST = 'SET_COLLECTION_DRESS_LIST'
export const CHANGE_LOADING_COLLECTION_DRESS_LIST = 'CHANGE_LOADING_COLLECTION_DRESS_LIST'
export const SET_COLLECTION_DATA = 'SET_COLLECTION_DATA'
export const CHANGE_LOADING_GET_COLLECTION_DATA = 'CHANGE_LOADING_GET_COLLECTION_DATA'
export const CHANGE_LOADING_ADD_DRESS_TO_CART = 'CHANGE_LOADING_ADD_DRESS_TO_CART'
export const SET_RETAILER_CART = 'SET_RETAILER_CART'
export const CHANGE_LOADING_RETAILER_CART = 'CHANGE_LOADING_RETAILER_CART'
export const CHANGE_LOADING_DOWNLOAD_CART = 'CHANGE_LOADING_DOWNLOAD_CART'
export const CHANGE_LOADING_RETAIL_EMAIL = 'CHANGE_LOADING_RETAIL_EMAIL'
export const CHANGE_LOADING_DELETE_DRESS = 'CHANGE_LOADING_DELETE_DRESS'
export const CHANGE_LOADING_SUBMIT_RETAILER = 'CHANGE_LOADING_SUBMIT_RETAILER'
export const LOADING_RETAIL_ORDERS = 'LOADING_RETAIL_ORDERS'
export const SET_RETAIL_ORDERS = 'SET_RETAIL_ORDERS'