import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  props: {
    product: {
      type: Object,
      default: ()=> {}
    },
    searchCard: {
      type: Boolean,
      default: ()=> false,
      required: false,
    }
  },
  name: "product-card",
  components:{
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      isRetailer: 'profile/isRetailer'
    }),
  },
  methods:{
    ...mapActions({}),
    ...mapMutations({
      addFavoriteProduct: 'basket/ADD_ITEM_TO_FAVORITES',
      removeFavoriteProduct: 'basket/REMOVE_ITEM_FROM_FAVORITES',
    }),
    changeFavorite(product){
      if(!product.selected){
        product.selected = true
        this.addFavoriteProduct(product)
      }
      else {
        product.selected = false
        this.removeFavoriteProduct(product)
      }
    },
  }
}